/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-3",
    "aws_cloud_logic_custom": [
        {
            "name": "virbacconnectApi",
            "endpoint": "https://kom3pquss0.execute-api.eu-west-3.amazonaws.com/pprd",
            "region": "eu-west-3"
        }
    ],
    "aws_cognito_region": "eu-west-3",
    "aws_user_pools_id": "eu-west-3_tYXDLyzBi",
    "aws_user_pools_web_client_id": "e0hcii3ciutfjtm83jhpkoaa",
    "oauth": {
        "domain": "cognito-connect-preprod.virbac.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://connect-preprod.virbac.com",
        "redirectSignOut": "https://connect-preprod.virbac.com/logout",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "virbacconnect-hostingbucket-pprd",
    "aws_content_delivery_bucket_region": "eu-west-3",
    "aws_content_delivery_url": "https://d1u6g0te9gh34x.cloudfront.net",
    "aws_dynamodb_all_tables_region": "eu-west-3",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "virbacconnect-pprd-dynamodb-table-clients",
            "region": "eu-west-3"
        },
        {
            "tableName": "virbacconnect-pprd-dynamodb-table-codes",
            "region": "eu-west-3"
        }
    ]
};


export default awsmobile;
